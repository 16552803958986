import React, { useEffect, useState } from 'react'
import stringLength from 'string-length'
import { Input, Radio } from '@nextui-org/react'
import Router from 'next/router'

import { MAX_FANME_ID_LENGTH, MAX_USER_NAME_LENGTH } from '@/constants'
import { Colors } from '@/constants/styles/color'
import { event } from '@/pages/api/gtag'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import FullModal from '@/components/atoms/Dialog/FullModal'
import { properNouns } from '@/constants/proper_nouns'

import { useUser } from '@/libs/fanme_backend'
import { putForm } from '@/pages/api/base'
import { validAccountIdentityUsed } from '@/libs/utils'
import { useCurrentUser } from '@/contexts/AuthContext'
import { useGeneralEvent } from '@/contexts/GeneralEventContext'
import { PURPOSES } from '@/constants/purpose'
import ModalHeaderWithSave from '@/components/molecules/Header/ModalHeaderWithSave'

import { useEditProfiletStore } from '@/utils/hooks/useProfileModal'
import ItemNotification from '../../Notification/ItemNotification'
import AvatarSelect from '../../AvatarSelect'

import {
  StyledContentContainer,
  StyledContentRow,
  StyledFormErrMsg,
  StyledFormLabel,
  StyledFormNote,
  StyledFormRequired,
  StyledFormTitle,
  StyledSpacer,
} from '../index.style'
import {
  StyledBirthdayContainer,
  StyledBirthdaySelect,
  StyledBirthdaySpan,
  StyledBirthdayClearButton,
} from './index.style'

interface Props {
  visible: boolean
  onClose: () => void
  toNext?: () => void
}

const _PREFIX_ = 'https://fanme.link/@'

const EditProfilePopup = ({ visible, onClose, toNext }: Props) => {
  const [avatarImage, setAvatarImage] = useState<any>(null)
  const [avatarCanvas, setAvatarCanvas] = useState<HTMLCanvasElement>()
  const [creatorName, setCreatorName] = useState('')
  const [accountIdentity, setAccountIdentity] = useState('')
  const [orinalAccountIdentity, setOrinalAccountIdentity] = useState('')
  const [fanmeIDAvailable, setFanmeIDAvailable] = useState(true)
  const [birthdayMonth, setBirthdayMonth] = useState<string>('')
  const [birthdayDay, setBirthdayDay] = useState<string>('')
  const [isBirthdayWeek, setIsBirthdayWeek] = useState<boolean>(false)
  const [visibleToast, setVisibleToast] = useState(false)
  const [creatorNameErrMessage, setCreatorNameErrMessage] = useState('')
  const [fanmeIdErrMessage, setFanmeIdErrMessage] = useState('')
  const [birthdayErrMessage, setBirthdayErrMessage] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [purpose, setPurpose] = useState(0)
  const [isValidCreatorName, setIsValidCreatorName] = useState(true)
  const [isEdited, setIsEdited] = useState(false)

  const { refreshCurrentUser } = useCurrentUser()
  const { onClose: onCloseEditProfile } = useEditProfiletStore()
  const { mutate: mutateUserData } = useUser()

  const { data: profile } = useUser()
  const generalEvent = useGeneralEvent()

  useEffect(() => {
    if (profile) {
      setAvatarImage(profile.icon)
      setCreatorName(profile.name)
      setAccountIdentity(profile.account_identity)
      setOrinalAccountIdentity(profile.account_identity)
      setPurpose(profile.purpose)
      let [year, month, day] = profile.birthday?.split('-').filter(Boolean) || ['', '', '']
      if (Number(year) < 1905) [year, month, day] = ['', '', ''] // 1905年以前は無効な日付として扱う（現状は1900年がほぼ全クリエイターに設定されているため）
      setBirthdayMonth(month)
      setBirthdayDay(day)
      setIsBirthdayWeek(profile.is_birthday_week === 1)
    }
  }, [profile])

  // 参加中のランキングを取得
  useEffect(() => {
    if (!generalEvent) return
    generalEvent.getActiveEvent(false)
  }, [generalEvent])

  const onChangeName = (e: any) => {
    const value = e.target.value.toString()
    setCreatorName(e.target.value)
    if (value.length === 0) {
      setCreatorNameErrMessage(`${properNouns.username}を入力してください`)
      setIsValidCreatorName(false)
      return
    }
    if (value.length > MAX_USER_NAME_LENGTH) {
      setCreatorNameErrMessage(`${MAX_USER_NAME_LENGTH}文字以内で入力してください`)
      setIsValidCreatorName(false)
      return
    }
    setCreatorNameErrMessage('')
    setIsValidCreatorName(true)
  }

  const goCreatorPage = (accountIdentity: string) => {
    const creatorPagePath = `/@${accountIdentity}`
    if (location.pathname !== creatorPagePath) {
      Router.push(creatorPagePath).then(() => Router.reload())
    } else {
      refreshCurrentUser()
      mutateUserData()
      onCloseEditProfile()
    }
  }

  const putCreatorData = async (formData: FormData) => {
    try {
      return await putForm('/creators/self', formData)
    } catch (e) {
      // TODO Error handling
    }
  }

  const updateProfile = async (formData: FormData) => {
    try {
      const creatorRes = await putCreatorData(formData)
      if (creatorRes && creatorRes.status === 200) {
        setLoaded(false)
        toNext ? toNext() : goCreatorPage(creatorRes.data.account_identity)
      }
    } catch (error) {
      console.error('Failed to update profile:', error)
    }
  }

  const updateProfileData = async () => {
    setLoaded(true)

    const formData = new FormData()
    formData.append('name', creatorName)
    formData.append('account_identity', accountIdentity)
    formData.append('purpose', purpose.toString())
    if (birthdayMonth && birthdayDay) {
      // NOTE: 誕生日は年を入れない設計にしているため、擬似的に1905年を設定
      formData.append('birthday', `1905-${birthdayMonth}-${birthdayDay}`)
    } else {
      formData.append('birthday', '1900-01-01') // 未設定の場合は1900-01-01に戻す
    }
    formData.append('is_birthday_week', isBirthdayWeek ? '1' : '0')
    // 画像変更時にblobがないままデータ更新が行われていたため、条件分岐を追加
    if (avatarCanvas) {
      const canvas = avatarCanvas as HTMLCanvasElement
      canvas.toBlob(async blob => {
        if (!blob) return
        if (blob) formData.append('icon', blob)
        updateProfile(formData)
      })
    }
    updateProfile(formData)
  }

  const onUpdate = async () => {
    let idValidation = true
    if (isEdited) {
      idValidation = await checkFanmeIDAvailability(accountIdentity)
    }
    if (!checkBirthdayWeek(birthdayMonth, birthdayDay)) return
    if (idValidation) updateProfileData()
    event({
      action: 'submit_form',
      category: 'information',
      value: 'update_profile',
    })
  }

  const onDiscard = () => {
    setAvatarImage(profile.icon)
    setCreatorName(profile.name)
    setAccountIdentity(profile.account_identity)
    setPurpose(profile.purpose)
    let [year, month, day] = profile.birthday?.split('-').filter(Boolean) || ['', '', '']
    if (Number(year) < 1905) [year, month, day] = ['', '', ''] // 1905年以前は無効な日付として扱う（現状は1900年がほぼ全クリエイターに設定されているため）
    setBirthdayMonth(month)
    setBirthdayDay(day)
    setIsBirthdayWeek(profile.is_birthday_week)
    onClose()
  }

  const onChangeAccountId = (accountId: string): void => {
    if (accountId !== orinalAccountIdentity) setIsEdited(true)
    setAccountIdentity(accountId)
    setFanmeIdErrMessage('')
    checkAccountIdValid(accountId)
  }

  const checkAccountIdValid = (accountId: string) => {
    if (accountId == undefined || accountId.length == 0) {
      setFanmeIdErrMessage(`${properNouns.fanmeId}を入力してください`)
      setFanmeIDAvailable(false)
      return
    }

    if (!accountId.match(/^[a-zA-Z0-9._]*$/)) {
      setFanmeIdErrMessage('使用できない文字が含まれています')
      setFanmeIDAvailable(false)
      return
    }

    if (accountId.endsWith('.')) {
      setFanmeIdErrMessage('末尾に「.」は使用できません')
      setFanmeIDAvailable(false)
      return
    }

    if (accountId.length > MAX_FANME_ID_LENGTH) {
      setFanmeIdErrMessage(`${MAX_FANME_ID_LENGTH}文字以内で入力してください`)
      setFanmeIDAvailable(false)
      return
    }

    setFanmeIDAvailable(true)
    return
  }

  const handleFocusOnName = () => {
    if (creatorName === 'FANMEユーザー') {
      setCreatorName('')
    }
  }

  const checkFanmeIDAvailability = async (accountIdentity: string) => {
    if (!fanmeIDAvailable) return false

    try {
      const notInUseAccountIdentity = await validAccountIdentityUsed(accountIdentity)
      if (notInUseAccountIdentity) {
        setFanmeIDAvailable(true)
        setVisibleToast(true)
        setFanmeIdErrMessage('')
        setTimeout(() => setVisibleToast(false), 2000)
        return true
      } else {
        setFanmeIdErrMessage(`この${properNouns.fanmeId}は既に利用されています`)
        setFanmeIDAvailable(false)
        return false
      }
    } catch (e) {
      setFanmeIdErrMessage(`この${properNouns.fanmeId}は既に利用されています`)
      setFanmeIDAvailable(false)
      return false
    }
  }

  const isValidDate = (year: string, month: string, day: string) => {
    const date = new Date(`${year}-${month}-${day}`)
    return (
      date.getFullYear() === Number(year) &&
      date.getMonth() + 1 === Number(month) &&
      date.getDate() === Number(day)
    )
  }
  const checkBirthdayWeek = (birthdayMonth: string, birthdayDay: string) => {
    // すべて未設定はok
    if (birthdayMonth === '' && birthdayDay === '') {
      setBirthdayErrMessage('')
      return true
    }
    if (!isValidDate('1905', birthdayMonth, birthdayDay)) {
      setIsBirthdayWeek(false)
      setBirthdayErrMessage('有効な年月日を入力してください')
      return false
    }
    setBirthdayErrMessage('')
    return true
  }

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(
      date.getDate(),
    ).padStart(2, '0')}`
  }

  const renderBirthdayWeekMessage = () => {
    const thisYearBirthday = new Date(`${new Date().getFullYear()}-${birthdayMonth}-${birthdayDay}`)
    const thisYearBirthdayWeekStartDate = new Date(thisYearBirthday)
    thisYearBirthdayWeekStartDate.setDate(thisYearBirthday.getDate() - 7)
    const thisYearBirthdayWeekEndDate = new Date(thisYearBirthday)
    thisYearBirthdayWeekEndDate.setDate(thisYearBirthday.getDate())

    const nextYearBirthday = new Date(
      `${new Date().getFullYear() + 1}-${birthdayMonth}-${birthdayDay}`,
    )
    const nextYearBirthdayWeekStartDate = new Date(nextYearBirthday)
    nextYearBirthdayWeekStartDate.setDate(nextYearBirthday.getDate() - 7)
    const nextYearBirthdayWeekEndDate = new Date(nextYearBirthday)
    nextYearBirthdayWeekEndDate.setDate(nextYearBirthday.getDate())

    const today = new Date()
    if (today < thisYearBirthdayWeekStartDate) {
      //今年の誕生日イベントがまだ来ていない or  準備期間中
      return `次回は ${formatDate(thisYearBirthdayWeekStartDate)}〜${formatDate(
        thisYearBirthdayWeekEndDate,
      )} に開催予定です`
    } else if (thisYearBirthdayWeekStartDate <= today && today < thisYearBirthdayWeekEndDate) {
      // Birthday Week中
      return `現在開催中です（日付変更した場合は明日以降に反映されます）`
    } else if (thisYearBirthdayWeekEndDate <= today) {
      // 今年の誕生日イベントが過ぎている
      return `次回は ${formatDate(nextYearBirthdayWeekStartDate)}〜${formatDate(
        nextYearBirthdayWeekEndDate,
      )} に開催予定です`
    } else {
      return ''
    }
  }

  const renderHeader = () => (
    <ModalHeaderWithSave
      title="プロフィール編集"
      onAction={onUpdate}
      onCancel={onDiscard}
      disabled={!isValidCreatorName || !fanmeIDAvailable}
    />
  )

  const renderContent = () => (
    <StyledContentContainer>
      <StyledFormLabel>
        <StyledFormTitle>プロフィール画像</StyledFormTitle>
      </StyledFormLabel>
      <AvatarSelect
        avatarImage={avatarImage}
        creatorName={creatorName}
        accountIdentity={accountIdentity}
        onGetCanvas={setAvatarCanvas}
        slowUpdate
      />

      <StyledFormLabel>
        <StyledFormTitle>
          {properNouns.username}
          <StyledFormRequired />
        </StyledFormTitle>
        <span>
          {stringLength(creatorName)} / {MAX_USER_NAME_LENGTH}
        </span>
      </StyledFormLabel>
      <Input
        size="md"
        shadow={false}
        animated={false}
        autoCapitalize="off"
        aria-label={properNouns.username}
        placeholder={`${properNouns.username}を入力`}
        css={{
          h: '48px',
          width: '100%',
          label: {
            backgroundColor: Colors.ULTRA_LIGHT_GRAY,
            boxShadow: !isValidCreatorName ? `0 0 0 1px ${Colors.RED}` : 'none',
            borderRadius: '8px',
            h: '48px',
          },
          '::placeholder': {
            color: Colors.LIGHT_GRAY,
          },
          input: {
            backgroundColor: Colors.ULTRA_LIGHT_GRAY,
            m: '0 !important',
            p: '0 12px',
            color: Colors.PRIMARY_GRAY,
            br: '8px',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '13px',
            h: '48px',
          },
        }}
        value={creatorName}
        onChange={onChangeName}
        onFocus={handleFocusOnName}
      />
      {!isValidCreatorName && <StyledFormErrMsg>{creatorNameErrMessage}</StyledFormErrMsg>}
      <StyledSpacer />

      <StyledFormLabel>
        <StyledFormTitle>
          {properNouns.fanmeId}
          <StyledFormRequired />
        </StyledFormTitle>
        <span>
          {stringLength(accountIdentity)} / {MAX_FANME_ID_LENGTH}
        </span>
      </StyledFormLabel>
      <StyledContentRow>
        {visibleToast && (
          <ItemNotification top={-40} text={`この${properNouns.fanmeId}は利用できます`} />
        )}
        <Input
          size="md"
          shadow={false}
          animated={false}
          autoCapitalize="off"
          placeholder="IDを入力"
          aria-label={properNouns.fanmeId}
          color={fanmeIDAvailable ? 'default' : 'error'}
          css={{
            h: '48px',
            width: '100%',
            '::placeholder': {
              color: Colors.LIGHT_GRAY,
            },
            label: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              borderRadius: '8px',
              boxShadow: !fanmeIDAvailable ? `0 0 0 1px ${Colors.RED}` : 'none',
              h: '48px',
            },
            span: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              paddingRight: '4px',
              fontSize: '11px',
              fontWeight: 500,
              lineHeight: '11px',
              color: Colors.PRIMARY_GRAY,
            },
            input: {
              backgroundColor: Colors.ULTRA_LIGHT_GRAY,
              m: '0 !important',
              paddingRight: '12px',
              color: Colors.PRIMARY_GRAY,
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              fontSize: '14px',
              fontWeight: 600,
              h: '48px',
            },
          }}
          labelLeft={_PREFIX_}
          value={accountIdentity}
          onChange={e => onChangeAccountId(e.target.value)}
          onBlur={checkFanmeIDAvailability.bind(null, accountIdentity)}
        />
      </StyledContentRow>

      {!fanmeIDAvailable && <StyledFormErrMsg>{fanmeIdErrMessage}</StyledFormErrMsg>}
      <StyledFormNote>
        {`* 半角英数字と記号（"." "_"）のみ使用できます。末尾に「.」は使用できません`}
      </StyledFormNote>
      <StyledSpacer />

      {/*利用目的*/}
      <StyledFormLabel>
        <StyledFormTitle>利用目的</StyledFormTitle>
      </StyledFormLabel>
      <Radio.Group
        css={{
          w: '100%',
          m: '8px 0',
          gap: '24px',
          '.radioItem': {
            m: 0,
          },
          d: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
        }}
        value={purpose}
        onChange={e => setPurpose(e as number)}
      >
        {PURPOSES.map(purposeOption => {
          return (
            <Radio
              size="lg"
              key={purposeOption.type}
              value={purposeOption.type}
              className="radioItem"
              css={{
                '.nextui-radio-point': {
                  m: '0',
                  marginRight: '8px',
                  '&::after': {
                    bg: Colors.WHITE,
                    border: `6px solid ${Colors.VERY_LIGHT_GRAY}`,
                    position: 'relative',
                  },
                },
                '.nextui-radio-active': {
                  '&::after': {
                    bg: Colors.WHITE,
                    border: `6px solid ${Colors.FANME_YELLOW} !important`,
                    m: '0',
                  },
                },
                '.nextui-radio-name': {
                  fontSize: '12px',
                  wordBreak: 'keep-all',
                  color: Colors.PRIMARY_GRAY,
                },
              }}
            >
              {purposeOption.name}
            </Radio>
          )
        })}
      </Radio.Group>
      <StyledSpacer />

      {/* 誕生日 */}
      <StyledContentRow>
        <StyledFormLabel>
          <StyledFormTitle>ANNIVERSARY</StyledFormTitle>
        </StyledFormLabel>
        <StyledBirthdayContainer>
          <StyledBirthdaySelect
            value={birthdayMonth}
            onChange={e => {
              if (generalEvent.activeEvent) return
              setBirthdayMonth(e.target.value)
              checkBirthdayWeek(e.target.value, birthdayDay)
            }}
            hasError={!!birthdayErrMessage}
            width="50px"
            disabled={!!generalEvent.activeEvent}
          >
            <option value="">--</option>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={String(i + 1).padStart(2, '0')}>
                {String(i + 1).padStart(2, '0')}
              </option>
            ))}
          </StyledBirthdaySelect>
          <StyledBirthdaySpan>月</StyledBirthdaySpan>
          <StyledBirthdaySelect
            value={birthdayDay}
            onChange={e => {
              if (generalEvent.activeEvent) return
              setBirthdayDay(e.target.value)
              checkBirthdayWeek(birthdayMonth, e.target.value)
            }}
            hasError={!!birthdayErrMessage}
            width="50px"
            disabled={!!generalEvent.activeEvent}
          >
            <option value="">--</option>
            {Array.from({ length: 31 }, (_, i) => (
              <option key={i} value={String(i + 1).padStart(2, '0')}>
                {String(i + 1).padStart(2, '0')}
              </option>
            ))}
          </StyledBirthdaySelect>
          <StyledBirthdaySpan>日</StyledBirthdaySpan>
          <StyledBirthdayClearButton
            onClick={() => {
              if (generalEvent.activeEvent) return
              setBirthdayMonth('')
              setBirthdayDay('')
              setBirthdayErrMessage('')
              setIsBirthdayWeek(false)
            }}
          >
            クリア
          </StyledBirthdayClearButton>
        </StyledBirthdayContainer>
        {birthdayErrMessage && <StyledFormErrMsg>{birthdayErrMessage}</StyledFormErrMsg>}
        {generalEvent.activeEvent && (
          <StyledFormNote>BIRTHDAY WEEK 開催中は変更できません</StyledFormNote>
        )}

        <StyledSpacer />

        {/* BIRTHDAY WEEK モード */}
        <StyledFormLabel>
          <StyledFormTitle>BIRTHDAY WEEK</StyledFormTitle>
        </StyledFormLabel>
        <Radio.Group
          css={{
            w: '100%',
            m: '8px 0',
            gap: '24px',
            '.radioItem': { m: 0 },
            d: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
          value={isBirthdayWeek ? 'true' : 'false'}
          onChange={e => {
            if (generalEvent.activeEvent) return
            setIsBirthdayWeek(e === 'true' ? true : false)
          }}
          disabled={
            !!generalEvent.activeEvent ||
            birthdayDay === '' ||
            birthdayMonth === '' ||
            birthdayErrMessage !== ''
          }
        >
          {[
            { type: 'true', name: 'オン' },
            { type: 'false', name: 'オフ' },
          ].map(birthdayWeekOption => {
            return (
              <Radio
                size="lg"
                key={birthdayWeekOption.type}
                value={birthdayWeekOption.type}
                className="radioItem"
                css={{
                  '.nextui-radio-point': {
                    m: '0',
                    marginRight: '8px',
                    '&::after': {
                      bg: Colors.WHITE,
                      border: `6px solid ${Colors.VERY_LIGHT_GRAY}`,
                      position: 'relative',
                    },
                  },
                  '.nextui-radio-active': {
                    '&::after': {
                      bg: Colors.WHITE,
                      border: `6px solid ${Colors.FANME_YELLOW} !important`,
                      m: '0',
                    },
                  },
                  '.nextui-radio-name': {
                    fontSize: '12px',
                    wordBreak: 'keep-all',
                    color: Colors.PRIMARY_GRAY,
                  },
                }}
              >
                {birthdayWeekOption.name}
              </Radio>
            )
          })}
        </Radio.Group>
        {generalEvent.activeEvent && (
          <StyledFormNote>BIRTHDAY WEEK 開催中は変更できません</StyledFormNote>
        )}
        {isBirthdayWeek && (
          <>
            {!generalEvent.activeEvent && (
              <StyledFormErrMsg>
                {renderBirthdayWeekMessage()}
                <br />
              </StyledFormErrMsg>
            )}
            <StyledFormErrMsg>
              ランキングイベント参加と期間が重なっていると開催されません
            </StyledFormErrMsg>
          </>
        )}
      </StyledContentRow>
    </StyledContentContainer>
  )

  if (!profile) return <FanmeLoading />
  return (
    <>
      {loaded && <FanmeLoading />}

      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="16px"
      />
    </>
  )
}

export default EditProfilePopup
