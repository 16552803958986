import React, { useState, useEffect, useRef, RefObject } from 'react'
import getConfig from 'next/config'
import Router, { useRouter } from 'next/router'
import Link from 'next/link'
import { TwitterShareButton } from 'react-share'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { useGeneralEvent, GeneralEventStatus } from '@/contexts/GeneralEventContext'
import { CreatorData } from '@/types/Creator.type'
import PopUpModal from '@/components/atoms/Dialog/PopUpModal'
import { getSellerApps } from '@/pages/api/payment'
import BalloonAction from '@/components/molecules/GeneralEvent/BalloonAction'

interface GeneralEventAreaForCreatorProps {
  loginUserData?: CreatorData | null
  creatorData?: CreatorData
  setVisibleRankingSharePopup: React.Dispatch<React.SetStateAction<boolean>>
  twitterShareButtonRef: RefObject<HTMLButtonElement>
  fanLetterButtonRef: RefObject<HTMLImageElement>
}

const StyledGeneralEventContainer = styled.div`
  margin: 16px 0;
  padding: 8px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: 100% auto;
  background-image: url('/general_event/background_fanme_pink.svg');
  background-repeat: repeat-y;
`

const StyledEventTitleImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`

const StyledEventTitleImage = styled.img`
  width: 100%;
`

const StyledCreatorRankingButtonsContainer = styled.div`
  text-align: center;
  min-width: 90%;
  margin-top: 16px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #ff8a00;
  background: #ffe7c0;
  border-radius: 16px;

  div.message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  span {
    margin-left: 8px;
  }
  img.yell-comment {
    height: 28px;
    margin: 0 4px;
  }
`

const StyledCreatorRankingButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0 0 0;
`

const StyledButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledCircleLoading = styled.div`
  box-sizing: border-box;
  margin: 0 70px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  animation: ${spin} 0.5s infinite linear;
`
const StyledYellCount = styled.span`
  position: absolute;
  top: 37%;
  right: 12%;
  font-size: 16px;
  z-index: 1;
`

const bounce = keyframes`
  0%, 100% {
    transform: scaleX(1) scaleY(1);
  }
  25% {
    transform: scaleX(1.1) scaleY(0.9);
  }
  50% {
    transform: scaleX(0.9) scaleY(1.1);
  }
  75% {
    transform: scaleX(1.05) scaleY(0.95);
  }
`

const createShakeKeyframes = (angle: number) => keyframes`
  0%, 84%, 100% {
    transform: rotate(0deg);
  }
  88%, 92%, 96% {
    transform: rotate(${angle}deg);
  }
  86%, 90%, 94%, 98% {
    transform: rotate(-${angle}deg);
  }
`

const StyledPostYellButtonImage = styled.img<{ isClicked: boolean }>`
  margin-top: 16px;
  width: 85%;
  animation: ${({ isClicked }) =>
    isClicked
      ? css`
          ${bounce} 0.25s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
        `
      : css`
          ${createShakeKeyframes(1)} 4s linear infinite;
        `};
`
const StyledPostYellButtonDisableImage = styled.img`
  width: 80%;
`

const StyledYellButtonImage = styled.img`
  height: 80px;
`

const StyledRankingButtonImage = styled.img`
  height: 64px;
`

const floatEmoji = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
    z-index: 100;
  }
`

const StyledFloatImage = styled.img<{ fileName: string }>`
  position: absolute;
  z-index: 100;
  width: ${({ fileName }) => (fileName === '99.webp' ? '100px' : '60px')};
  animation: ${floatEmoji} 1s ease-in-out forwards;
  pointer-events: none;
`

const sparklingIcon = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
    z-index: 100;
  }
`

const StyledSparklingIcon = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 100;
  font-size: 34px;
  animation: ${sparklingIcon} 0.5s ease-in-out forwards;
  pointer-events: none;
`

const StyledPopupModalContainer = styled.div`
  z-index: 10002;
`

export const StyledEventBirthdayLabel = styled.div`
  position: absolute;
  bottom: clamp(16px, 3.75vw, 18px);
  left: clamp(16px, 24.375vw, 117px);
  z-index: 1;
  font-size: clamp(10px, calc(7.2vw - 3px), 32px);
  font-weight: bold;
  color: #ff208a;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white,
    3px 3px 0px #bfbfbf;
`

export const StyledEventBirthdayLabelTilde = styled.span`
  color: #fe57a6;
`

// クリエイターページのランキングエリアを表示するコンポーネント
const GeneralEventAreaForCreator = React.forwardRef(
  (
    {
      loginUserData,
      creatorData,
      setVisibleRankingSharePopup,
      fanLetterButtonRef,
      twitterShareButtonRef,
    }: GeneralEventAreaForCreatorProps,
    ref,
  ) => {
    const { publicRuntimeConfig } = getConfig()
    const router = useRouter()

    // ランキング関連
    const generalEvent = useGeneralEvent()
    const buttonPostYellRef = useRef<HTMLImageElement>(null)
    const [isPostYellClicked, setIsPostYellClicked] = useState(false)
    type IconPosition = {
      symbol: string
      x: number
      y: number
    }
    const [yellEmojis, setYellEmojis] = useState<IconPosition[]>([])
    const [sparklingIcons, setSparklingIcons] = useState<IconPosition[]>([])
    const [yellClickCount, setYellClickCount] = useState<number | null>(null)
    const [postYellTimerId, setPostYellTimerId] = useState<NodeJS.Timeout | null>(null)
    const [visibleEventPopUp, setVisibleEventPopUp] = useState<boolean>(false)

    const [apps, setApps] = useState<MiniAppInfo[] | null>(null)
    const [fanLetterUrl, setFanLetterUrl] = useState<string | null>(null)

    // 参加中のランキングを取得
    useEffect(() => {
      if (!generalEvent) return
      generalEvent.getActiveEvent(false)
    }, [generalEvent])

    // ログイン済みの場合、エール送信の状態（送信可能かどうかなど）を取得
    useEffect(() => {
      // ログインしてない場合はサーバから取得せず、ローカルで設定（エールを送るやシェアのクリックでログインを促す）
      if (!loginUserData) {
        generalEvent.setActionable({
          can_yell_for_share: true,
          can_yell_for_click: true,
          today_yell_count: 0,
        })
        return
      }
      // ログインしている場合はサーバからエール送信の状態を取得
      const fetchData = async () => {
        if (!generalEvent.activeEvent) return
        const accountIdentity = generalEvent.activeEvent?.creator.account_identity || ''
        const actionable = await generalEvent.getActionable(accountIdentity)
        setYellClickCount(actionable?.today_yell_count || 0)
      }
      fetchData()
    }, [generalEvent.activeEvent, loginUserData])

    // エールをサーバに送付する
    // 連打されたときにサーバ負荷が高いので、0.5秒後に送付する
    // ただし0.5秒以内に処理がきた場合は前の処理はキャンセルし、次の処理にてまとめて送付する
    const postYellTimerProp = (yellClickCount: number) => {
      if (postYellTimerId) {
        clearTimeout(postYellTimerId)
      }
      const timerId = setTimeout(() => {
        postYellProp(yellClickCount || 0)
      }, 500)
      setPostYellTimerId(timerId)
    }
    // エールのサーバ送信処理
    const postYellProp = (postYell: number) => {
      if (postYell <= 0) return
      if (!generalEvent.activeEvent) return
      const accountIdentity = generalEvent.activeEvent?.creator.account_identity
      generalEvent.postYell(accountIdentity, generalEvent.YellLogType.Click, postYell)
    }

    // エール送信アクション処理
    const handlePostYell = (event: React.MouseEvent) => {
      if (!generalEvent.activeEvent) return
      // はじめに音尾出す
      const audio = new Audio(`/general_event/post_yell.mp3`)
      audio.play()
      // ボタンのぷにぷにアニメーション制御
      setIsPostYellClicked(true)
      setTimeout(() => setIsPostYellClicked(false), 250)
      // エール用の絵文字ふよふよ処理
      const yellEmoji = fluffyYellEmoji()
      // エールのカウントアップ（spacialのときは一気に100エールになる）
      const postYell = Math.min(
        yellEmoji == '99.webp' ? countableMaxYell() : (yellClickCount ?? 0) + 10,
        countableMaxYell(),
      )
      setYellClickCount(postYell)
      // エールの実際の送信を行う
      postYellTimerProp(postYell)
      // エールの送信完了処理
      if (postYell >= countableMaxYell()) {
        finishalizePostYell(postYell)
      }
    }
    const getRandomInt = (min: number, max: number) => {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
    // ランダムでエール用の絵文字を返す
    const getYellEmoji = (): string => {
      const emojis = ['1.webp', '2.webp', '3.webp']
      const specialEmoji = '99.webp'
      const randomValue = Math.random()
      // 1%の確率で special を返す
      if (randomValue <= 0.01) {
        return specialEmoji
      } else {
        const randomIndex = Math.floor(Math.random() * emojis.length)
        return emojis[randomIndex]
      }
    }
    // エール用の絵文字ふよふよ処理
    const fluffyYellEmoji = (): string => {
      const yellEmoji = getYellEmoji()
      if (buttonPostYellRef.current) {
        const newYellEmoji = {
          symbol: yellEmoji,
          x: buttonPostYellRef.current.width / 2 + getRandomInt(-80, 80),
          y: -20,
        }
        setYellEmojis(prevYellEmojis => [...prevYellEmojis, newYellEmoji])
      }
      return yellEmoji
    }
    // エール送信完了処理
    const finishalizePostYell = (postYell: number) => {
      // エール送信ボタンを押せなくする
      generalEvent.setActionable({
        can_yell_for_share: generalEvent.actionable?.can_yell_for_share || false,
        can_yell_for_click: false,
        today_yell_count: postYell,
      })
      // キラキラを表示
      sparklingsAfterYell()
      // シェアがまだの場合、シェアを促す
      if (generalEvent.actionable?.can_yell_for_share) {
        // すぐに表示するとタップの絵文字アニメーションがかき消されてしまうため、1秒ほど時間を置いてから表示
        setTimeout(() => {
          setVisibleRankingSharePopup(true)
        }, 1000)
      }
    }
    // エール送信完了時のキラキラ表示
    const sparklingsAfterYell = () => {
      if (!buttonPostYellRef.current) return
      const rect = buttonPostYellRef.current.getBoundingClientRect()
      for (let i = 0; i < 25; i++) {
        setTimeout(() => {
          sparklingAfterYell(rect)
        }, i * 12)
      }
    }
    const sparklingAfterYell = (rect: DOMRect) => {
      const newIcon = {
        symbol: '✨',
        x: rect.width / 2 - rect.left + getRandomInt(-200, 200),
        y: getRandomInt(-100, 100),
      }
      setSparklingIcons(prevIcons => [...prevIcons, newIcon])
    }

    // イベントポップアップの制御（1日に1回表示する）
    useEffect(() => {
      if (!generalEvent.activeEvent) return
      const currentTime = new Date()
      const eventStartTime = new Date(generalEvent.activeEvent.start_at)
      const eventEndTime = new Date(generalEvent.activeEvent.end_at)
      if (eventStartTime > currentTime || eventEndTime < currentTime) return
      const lastVisibleTime = localStorage.getItem('visibleGeneralEventPopupDialog')
      const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000
      if (!lastVisibleTime || Number(lastVisibleTime) < oneDayAgo) {
        setVisibleEventPopUp(true)
        localStorage.setItem('visibleGeneralEventPopupDialog', String(Date.now()))
      }
    }, [generalEvent.activeEvent])

    // 音声を事前ロード
    useEffect(() => {
      new Audio('/general_event/post_yell.mp3')
    }, [])

    // デジタルファンレターのリンクを取得
    useEffect(() => {
      if (!generalEvent.activeEvent) return
      if (!creatorData) return
      if (apps) return
      getSellerApps(creatorData.uid).then(res => {
        setApps(res)
        const fanLetterApp = res.find((app: MiniAppInfo) => app.app_url_key === 'dfl')
        if (fanLetterApp) {
          setFanLetterUrl(
            `/${router.query.creator_id}/apps/${fanLetterApp.app_url_key}${fanLetterApp.seller_app_url_key}?from=birthday_week`,
          )
        }
      })
    }, [generalEvent.activeEvent, creatorData])

    if (!generalEvent.activeEvent) return <></>
    const status = generalEvent.getEventStatus(generalEvent.activeEvent)
    switch (status) {
      case GeneralEventStatus.Ongoing: // 開催中
      case GeneralEventStatus.Preparing: // 準備中
        break // 以下でrendering
      case GeneralEventStatus.EventFinished: // イベント終了
      case GeneralEventStatus.AnnouncingResults: // 結果発表中
        return <></> // joiningEventとして返ってくることはないため、実際は表示されることはない
    }

    const scrollRankingAnchor = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      const anchor = document.querySelector('.ranking-anchor')
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const countableMaxYell = (): number => {
      return generalEvent.YellCount.ClickMax
    }

    const formatBirthday = (date: Date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${month}/${day}`
    }

    const getTitleImageSrc = (status: GeneralEventStatus) => {
      switch (status) {
        case GeneralEventStatus.Ongoing: // 開催中
          return 'general_event/event-title.webp'
        case GeneralEventStatus.Preparing: // 準備中
          return 'general_event/event-title-prepare.webp'
        default:
          return ''
      }
    }

    // 開催中のレンダリング
    return (
      <StyledGeneralEventContainer>
        {/* イベントアイコン */}
        <StyledEventTitleImageWrapper>
          <Link
            href={`/@${generalEvent.activeEvent.creator.account_identity}/event/${generalEvent.activeEvent.id}/info`}
            style={{ width: '90%', zIndex: 2 }}
          >
            <StyledEventTitleImage src={getTitleImageSrc(status)} />
          </Link>
          <StyledEventBirthdayLabel className="font-Montserrat">
            {formatBirthday(new Date(generalEvent.activeEvent.start_at))}&nbsp;
            <StyledEventBirthdayLabelTilde>~</StyledEventBirthdayLabelTilde>&nbsp;
            {formatBirthday(new Date(generalEvent.activeEvent.end_at))}
          </StyledEventBirthdayLabel>
        </StyledEventTitleImageWrapper>

        {status === GeneralEventStatus.Ongoing && (
          <>
            <BalloonAction />
            {/* エールを送るボタン */}
            <StyledButtonsWrapper>
              {yellEmojis.map((emoji, index) => (
                <StyledFloatImage
                  src={`/general_event/yell/${emoji.symbol}`}
                  fileName={emoji.symbol}
                  key={index}
                  style={{ left: emoji.x, top: emoji.y }}
                ></StyledFloatImage>
              ))}
              {sparklingIcons.map((icon, index) => (
                <StyledSparklingIcon key={index} style={{ left: icon.x, top: icon.y }}>
                  {icon.symbol}
                </StyledSparklingIcon>
              ))}
              {generalEvent.actionable?.can_yell_for_click && (
                <StyledYellCount>
                  {yellClickCount || 0}/{countableMaxYell()}
                </StyledYellCount>
              )}
              {generalEvent.actionable?.can_yell_for_click ? (
                <StyledPostYellButtonImage
                  ref={buttonPostYellRef}
                  src="/general_event/post_yell_button.webp"
                  alt="post yell button"
                  onClick={(event: React.MouseEvent) => {
                    // ログインしてない場合はログインへ遷移
                    if (!loginUserData) {
                      Router.push(
                        `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`,
                      )
                      return
                    }
                    // エールポスト処理実行
                    handlePostYell(event)
                  }}
                  isClicked={isPostYellClicked}
                />
              ) : generalEvent.actionable?.today_yell_count &&
                generalEvent.actionable?.today_yell_count >= countableMaxYell() ? (
                <StyledPostYellButtonDisableImage
                  src="/general_event/post_yell_button_completed.svg"
                  alt="post yell button"
                />
              ) : (
                <StyledCircleLoading></StyledCircleLoading>
              )}
            </StyledButtonsWrapper>

            {/* エール送付郡ボタンの表示 */}
            <StyledCreatorRankingButtonsContainer>
              <div className="message">
                <>
                  <span>更にお祝いしよう!</span>
                  <img
                    src="/general_event/yell_comment_icon.webp"
                    alt="yell comment icon button"
                    className="yell-comment"
                  />
                </>
              </div>
              <StyledCreatorRankingButtons className="buttons">
                {/* シェアボタン */}
                {generalEvent.actionable?.can_yell_for_share ? (
                  <TwitterShareButton
                    ref={twitterShareButtonRef}
                    url={`${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`}
                    via={`FANME__official`}
                    title={`「${creatorData?.name}」さん誕生日おめでとう！！！\n素晴らしい一年に✨`}
                    hashtags={['FANME', 'バースデーウィーク']}
                    onClick={async () => {
                      // ログインしてない場合はログインへ遷移
                      if (!loginUserData) {
                        Router.push(
                          `${publicRuntimeConfig.AUTH_URL}?return_url=${publicRuntimeConfig.FRONT_URL}/${router.query.creator_id}`,
                        )
                        return
                      }
                      // ログインしている場合のみエール付与
                      if (!generalEvent.activeEvent) return
                      await generalEvent.postYell(
                        generalEvent.activeEvent.creator.account_identity,
                        generalEvent.YellLogType.Share,
                        generalEvent.YellCount.Share,
                      )
                      generalEvent.getActionable(generalEvent.activeEvent.creator.account_identity)
                    }}
                  >
                    <StyledYellButtonImage
                      src="/general_event/share_ranking_button.webp"
                      alt="share ranking button"
                    />
                  </TwitterShareButton>
                ) : (
                  <StyledYellButtonImage
                    src="/general_event/share_ranking_button.webp"
                    alt="share ranking button"
                    style={{ opacity: 0.5 }}
                  />
                )}
                {/* 購入ボタン */}
                <a href="#" onClick={scrollRankingAnchor}>
                  <StyledYellButtonImage
                    src="/general_event/purchase_ranking_button.webp"
                    alt="purchase ranking button"
                  />
                </a>
                {/* デジタルファンレターボタン */}
                {fanLetterUrl && (
                  <StyledYellButtonImage
                    ref={fanLetterButtonRef}
                    src="/general_event/fan_letter_ranking_button.webp"
                    alt="fan letter ranking button"
                    onClick={() => {
                      Router.push(fanLetterUrl)
                    }}
                  />
                )}
              </StyledCreatorRankingButtons>
            </StyledCreatorRankingButtonsContainer>

            {/* ファンランキングボタン */}
            <StyledCreatorRankingButtons>
              <Link
                href={`/@${generalEvent.activeEvent.creator.account_identity}/event/${generalEvent.activeEvent.id}/fans`}
              >
                <StyledRankingButtonImage
                  src="/general_event/fan_ranking_button.webp"
                  alt="fan ranking button"
                />
              </Link>
            </StyledCreatorRankingButtons>

            {/* イベントポップアップ */}
            {setVisibleEventPopUp && (
              <StyledPopupModalContainer>
                <PopUpModal
                  visible={visibleEventPopUp}
                  setVisible={setVisibleEventPopUp}
                  popUpData={{
                    enable: true,
                    url: `@${generalEvent.activeEvent.creator.account_identity}/event/${generalEvent.activeEvent.id}/info`,
                    button_text: 'イベント詳細はこちら',
                    title: `「バースデイウィーク」を実施中です！`,
                    image: '/general_event/main-image.webp',
                    updated_at: new Date().toLocaleDateString(),
                  }}
                />
              </StyledPopupModalContainer>
            )}
          </>
        )}
      </StyledGeneralEventContainer>
    )
  },
)

GeneralEventAreaForCreator.displayName = 'GeneralEventAreaForCreator'
export default GeneralEventAreaForCreator
